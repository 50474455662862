import player from "lottie-web";
import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {provideLottieOptions} from "ngx-lottie";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {headersInterceptor} from "./core/interceptor/headers.interceptor";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire/compat";
import {provideRouter, withViewTransitions} from "@angular/router";
import {routes} from "./app.routes";

function playerFactory() {
    return player;
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withViewTransitions()),
        /*provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),*/
        importProvidersFrom(BrowserModule, AngularFireModule.initializeApp(environment.firebase), AngularFireDatabaseModule),
        provideLottieOptions({
            player: () => player,
        }),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi(), withInterceptors([headersInterceptor])),
    ]
}
