import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-chat',
    templateUrl: './app-chat.component.html',
    styleUrls: ['./app-chat.component.scss'],
    standalone: true
})
export class AppChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
