import { inject } from "@angular/core";
import { Router, type CanActivateFn } from "@angular/router";
import { AuthService } from "../../auth/services/auth.service";
import { AuthStatus } from "../../auth/interfaces";

export const isNotAuthenticatedGuard: CanActivateFn = (route, state) => {
    // const url = state.url;
    // localStorage.setItem('url', url);

    const authService = inject(AuthService);
    const router = inject(Router);
    // console.log({
    //     status: authService.authStatus(),
    // });
    // console.log("isAuthenticatedGuard", route, state);

    if (authService.authStatus() === AuthStatus.authenticated) {
        // console.log("isNotAuthenticatedGuard ingreso al if", route, state);
        router.navigateByUrl("/dashboard/inicio");
        return false;
    } else {
        // console.log("isNotAuthenticatedGuard NO ingreso al if", route, state);

        return true;
    }
};
