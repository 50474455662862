<!-- Start Login Area -->
<div class="login-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="login-form">
                <div class="logo">
                    <a routerLink="/dashboard/analytics"><img src="assets/img/logo.svg" alt="image"></a>
                </div>

                <h2>Bienvenido</h2>

                <form [formGroup]="myForm" (ngSubmit)="login()">
                    <div class="form-group">
                        <input type="text" class="form-control" name="email" placeholder="Email" formControlName="email">
                        <span class="label-title"><i class='bx bx-user'></i></span>
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" name="password" placeholder="Password" formControlName="password">
                        <span class="label-title"><i class='bx bx-lock'></i></span>
                    </div>

                    <div class="form-group">
                        <div class="remember-forgot">
                            <!-- <label class="checkbox-box">Remember me
                                <input type="checkbox">
                                <span class="checkmark"></span>
                            </label> -->

                            <a routerLink="/authentication/forgot-password" class="forgot-password">olvidaste tu contraseña?</a>
                        </div>
                    </div>

                    <button [disabled]="myForm.invalid" type="submit" class="login-btn">Iniciar Sesión</button>

                    <!-- <p class="mb-0">Don’t have an account? <a routerLink="/authentication/register">Sign Up</a></p> -->
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->