import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import Swal from "sweetalert2";
import { Router, RouterLink } from "@angular/router";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    standalone: true,
    imports: [RouterLink, ReactiveFormsModule],
})
// export class LoginComponent implements OnInit {
export class LoginComponent {
    private fb = inject(FormBuilder);
    private authService = inject(AuthService);
    private router = inject(Router);

    constructor() {}

    public myForm: FormGroup = this.fb.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
    });
    login() {
        const { email, password } = this.myForm.value;
        this.authService.login(email, password).subscribe({
            next: () => this.router.navigateByUrl("/dashboard"),
            error: (err) => {
                Swal.fire("Error", err, "error");
            },
        });
    }
    // ngOnInit() {}
}
