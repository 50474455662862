// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // baseUrl: "http://localhost:3000/coonverso/v1",
    baseUrl: "https://api.coonverso.com/coonverso/v1",
    baseApiUrl: "https://api.coonverso.com/micro-web/v1",
    // baseApiUrl: "http://localhost:3000/micro-web/v1",

    avatarURl: "https://api.dicebear.com/9.x/identicon/svg?seed=",
    firebase: {
        apiKey: "AIzaSyCHbfBHnn7GwQi-xc0GeEy-ZJMuN-RYQvc",
        authDomain: "coonverso.firebaseapp.com",
        databaseURL: "https://coonverso-default-rtdb.firebaseio.com",
        projectId: "coonverso",
        storageBucket: "coonverso.appspot.com",
        messagingSenderId: "265380793269",
        appId: "1:265380793269:web:ca4512b0d682de636027fc",
        measurementId: "G-1WHSKPWE3Z",
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
