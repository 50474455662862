import { HttpClient, HttpHeaders } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import {
    AuthStatus,
    CheckTokenResponse,
    LoginResponse,
    User,
} from "../interfaces";
import { ApiResponse } from "src/app/dashboard/pages/anuncios/interfaces/response.interface";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly baseUrl: string = environment.baseUrl;
    private http = inject(HttpClient);

    private _currentUser = signal<User | null>(null);
    private _authStatus = signal<AuthStatus>(AuthStatus.checking);
    private _userRoles = signal<string[]>([]);

    //publica
    public currentUser = computed(() => this._currentUser());
    public authStatus = computed(() => this._authStatus());
    public userRoles = computed(() => this._userRoles());

    constructor() {
        this.checkAuthStatus().subscribe();
    }

    private setAuthentication(
        user: User,
        token: string,
        roles: string[]
    ): boolean {
        this._currentUser.set(user);
        this._authStatus.set(AuthStatus.authenticated);
        this._userRoles.set(roles);
        localStorage.setItem("token", token);
        return true;
    }

    login(email: string, password: string): Observable<boolean> {
        const url = `${this.baseUrl}/auth/login`;
        const body = { email, password };

        return this.http.post<ApiResponse<LoginResponse>>(url, body).pipe(
            map((response) => {
                console.log(response);
                const roles = response.data.user.roles; // Asegúrate de que el backend envíe los roles del usuario
                this.setAuthentication(
                    response.data.user,
                    response.data.token,
                    roles
                );
                return true;
            }),
            catchError((err) => throwError(() => err.error.message))
        );
    }
    getCurrentUserEmail(): string | null {
        const currentUser = this._currentUser();
        return currentUser ? currentUser.email : null;
    }
    checkAuthStatus(): Observable<boolean> {
        const url = `${this.baseUrl}/auth/check-token`;
        const token = localStorage.getItem("token");

        if (!token) {
            this.logout();
            return of(false);
        }

        const headers = new HttpHeaders().set(
            "Authorization",
            `Bearer ${token}`
        );
        return this.http
            .get<ApiResponse<CheckTokenResponse>>(url, { headers })
            .pipe(
                map((response) => {
                    const roles = response.data.user.roles; // Asegúrate de que el backend envíe los roles del usuario
                    return this.setAuthentication(
                        response.data.user,
                        response.data.token,
                        roles
                    );
                }),
                catchError(() => {
                    this._authStatus.set(AuthStatus.notAuthenticated);
                    return of(false);
                })
            );
    }

    logout() {
        this._currentUser.set(null);
        this._authStatus.set(AuthStatus.notAuthenticated);
        this._userRoles.set([]);
        localStorage.removeItem("token");
    }
}
