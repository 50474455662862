import { Routes } from "@angular/router";
import { AppChatComponent } from "./components/pages/app-chat/app-chat.component";
import { AppTodoComponent } from "./components/pages/app-todo/app-todo.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { isAuthenticatedGuard } from "./core/guards/isAuthenticated.guard";
import { isNotAuthenticatedGuard } from "./core/guards/isNotAuthenticated.guard";
import { LoginComponent } from "./auth/pages/login/login.component";
import { RegisterComponent } from "./auth/pages/register/register.component";
import { Inicio2Component } from "./dashboard/pages/inicio2/inicio2.component";

export const routes: Routes = [
    {
        path: "auth",
        canActivate: [isNotAuthenticatedGuard],
        loadComponent: () =>
            import("./layouts/auth-layout/auth-layout.component"),
        children: [
            {
                path: "login",
                component: LoginComponent,
            },
            {
                path: "register",
                component: RegisterComponent,
            },
            {
                path: "**",
                redirectTo: "login",
                pathMatch: "full",
            },
        ],
    },
    {
        path: "dashboard",
        canActivate: [isAuthenticatedGuard],
        loadComponent: () =>
            import("./layouts/admin-layout/admin-layout.component"),
        children: [
            // {
            //     path: "app-todo",
            //     component: AppTodoComponent,
            // },
            // {
            //     path: "inicio",
            //     component: Inicio2Component,
            // },
            {
                path: "inicio",
                loadComponent: () =>
                    import("./dashboard/pages/chat/chat.component"),
            },
            { path: "profile", component: ProfileComponent },
            {
                path: "anuncio",
                loadChildren: () =>
                    import("./dashboard/pages/anuncios/anuncios.module").then(
                        (m) => m.AnunciosModule
                    ),
            },
            {
                path: "reportes",
                loadChildren: () =>
                    import("./dashboard/pages/reportes/reportes.module").then(
                        (m) => m.ReportesModule
                    ),
            },
            {
                path: "chat",
                loadComponent: () =>
                    import("./dashboard/pages/chat/chat.component"),
            },
            {
                path: "canales",
                loadComponent: () =>
                    import(
                        "./dashboard/pages/canales/facebook-login/facebook-login.component"
                    ).then((m) => m.FacebookLoginComponent),
            },
            {
                path: "**",
                redirectTo: "inicio",
                pathMatch: "full",
            },
        ],
    },
    {
        path: "**",
        redirectTo: "auth",
    },
    { path: "app-chat", component: AppChatComponent },
    { path: "app-todo", component: AppTodoComponent },
    { path: "profile", component: ProfileComponent },
];
