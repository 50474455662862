import { HttpInterceptorFn } from "@angular/common/http";

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
    const modifiedReq = req.clone({
        setHeaders: {
            // "x-coonverso-widget": "HBnBV6Xju3t24wu8UHGi",
            "x-coonverso-widget": "Nn7hK8d3BNCYZ21QXPoi",
            // "x-coonverso-mail": "meta@hotmail.com" || "",
            "x-coonverso-mail": "",
        },
    });

    return next(modifiedReq);
};
