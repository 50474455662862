import {
    Component,
    OnInit,
    OnDestroy,
    inject,
    computed,
    effect,
} from "@angular/core";
import {Router, NavigationCancel, NavigationEnd, NavigationStart, RouterOutlet} from "@angular/router";
import {Location, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {filter} from "rxjs/operators";
import {AuthService} from "./auth/services/auth.service";
import {AuthStatus} from "./auth/interfaces";

declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    private authService = inject(AuthService);
    private currentUrl: string = "";

    public finishedAuthCheck = computed<boolean>(() => {
        console.log(this.authService.authStatus());
        if (this.authService.authStatus() === AuthStatus.checking) {
            return false;
        }
        return true;
    });

    public authStatusChangedEffect = effect(() => {
        // console.log("Efecto de cambio de estado de autenticación");
        // console.log(this.authService.authStatus());
        switch (this.authService.authStatus()) {
            case AuthStatus.checking:
                return;

            case AuthStatus.authenticated:
                // console.log("Usuario autenticado");
                if (this.currentUrl) {
                    this.router.navigateByUrl(this.currentUrl);
                    this.currentUrl = ""; // Limpiar la URL después de la navegación
                } else {
                    this.router.navigateByUrl("/dashboard/inicio");
                }
                return;

            case AuthStatus.notAuthenticated:
                this.router.navigateByUrl("/auth/login");
                return;
        }
    });

    constructor(private router: Router) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                // console.log("Navegación a:", event.url);
                this.currentUrl = event.url;
            });
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/custom.js");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
